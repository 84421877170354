import React, { useState } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import SessionAgreement from '../components/trainingsComponents/sessionAgreement'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import LeftContainer from '../components/trainingsComponents/leftContainer'
import RightContainer from '../components/trainingsComponents/rightContainer'
import TrainingFormHeader from '../components/trainingsComponents/traningFormHeader'
import SuccessFormModal from '../components/modals/successFormModal'

const CoachingForm = () => {
    const [openModal, setOpenModal ] = useState(false)
    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is required'),
        lastName: Yup.string()
            .required('Last Name is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        agreeTerms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required'),
        phone: Yup.string()
            .required('Phone is required'),
        address1: Yup.string()
            .required('Street Address is required'),
        city: Yup.string()
            .required('City is required'),
        zip: Yup.string()
            .required('Zip code is required'),
        country: Yup.string()
            .required('Country is required'),
        trainingType: Yup.string()
            .required('Please choose session type.'),
        typeOfSession: Yup.string()
            .required('Session topic is required'),     
    })

    return (
        <Layout>
            <Seo title="Fill the training Form" description="Training form, preorder process"/>
            <Formik
                initialValues={{
                    firstName:'',
                    lastName:'',
                    email: '',
                    phone: '',
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: '',
                    company: '',
                    jobTitle: '',
                    sessionDate: new Date (),
                    typeOfSession: '',
                    comments: '',
                    agreeTerms: false,
                }}
                validationSchema= {validationSchema}
                onSubmit={async (values) => {
                    fetch('https://send-apply-form.infra-ricta-cloudflare-test.workers.dev/',{
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        },
                        method:'POST',
                        body: JSON.stringify(values)
                    })
                        .then(res=>res.json())
                        .then(data=>{
                            
                            console.log('data',data)
                            if(data && data.ok===true)
                                setOpenModal(true)

                        },
                        error=>console.log('err',error)
                        )
                }}
            >
                {({ values, setFieldValue, isValid,dirty }) => ( 
                    <div className="sm:m-4 max-w-screen-xl w-4/5 md:w-full m-auto">
                        <div className="relative px-6 xl:px-2 py-4">
                            <Form>
                                <TrainingFormHeader title={'Coaching/Consultancy Inquiry form'}/>
                                <div className="divide-gray-500 grid md:grid-cols-2 md:divide-x md:space-x-2">
                                    <LeftContainer/>
                                    <RightContainer values={values} setFieldValue={setFieldValue} coaching={true}/>  
                                </div>
                                <div className="mt-2">
                                    <label className="text-left col-span-2 lg:col-span-1 leading-loose sm:text-base text-sm ml-2 requiredBefore ">These fields are mandatory</label>
                                    <SessionAgreement coaching={true}/>
                                </div>
                                <div className="flex flex-col justify-center sm:justify-start my-2">
                                    <div className="flex items-center ">
                                        
                                        <Field type="checkbox" name="agreeTerms" />
                                        <label htmlFor="Iagree" className="ml-1">I agree</label>
                                    </div>
                                    <div className="flex">
                                        <div className="text-red-500 text-xs">
                                            <ErrorMessage name="agreeTerms" />
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-4 flex items-center justify-center space-x-4">
                                    <button disabled={!isValid && !dirty  } type="submit" className={`${isValid && dirty ? 'bg-mainColor' : 'bg-gray-200'} flex justify-center items-center text-white px-10 py-3 rounded-md focus:outline-none`}>Submit</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                )}
            </Formik>
            {openModal && <SuccessFormModal modalIsOpen={openModal} setModalIsOpen={setOpenModal} text={(<p>Your request has been successfully sent. We will contact you very soon!<br/>If you do not see our request confirmation email in a few minutes, check your “junk mail” folder or “spam” folder and add our email address to your Not Spam or Safe Sender List.<br/> If you did not receive a confirmation email please contact us at contact@rictatechnologies.com</p>)}/>}
        </Layout>

    )
}
export default CoachingForm